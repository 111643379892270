<template>
    <el-main>
        <el-form class="el-form-search" label-width="100px">
            <el-form-item label="状态：">
                <el-select v-model="searchForm.video_status" size="small" placeholder="请选择">
                    <el-option label="全部" :value="999"></el-option>
                    <el-option label="待审核" :value="0"></el-option>
                    <el-option label="已通过" :value="1"></el-option>
                    <el-option label="未通过" :value="-1"></el-option>
                    <el-option label="未上传" :value="-2"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="店铺名称：">
                <el-input size="small" v-model="searchForm.merchant_name" placeholder="请输入店铺名称"></el-input>
            </el-form-item>
            <el-form-item label="商家姓名：">
                <el-input size="small" v-model="searchForm.contact_name" placeholder="请输入商家姓名"></el-input>
            </el-form-item>
            <el-form-item label="商家电话：">
                <el-input size="small" v-model="searchForm.store_phone" placeholder="请输入商家电话"></el-input>
            </el-form-item>
            <el-form-item label="操作时间：">
                <el-date-picker size="small" v-model="time" type="daterange" range-separator="至" start-placeholder="开始日期"
                    end-placeholder="结束日期"></el-date-picker>
            </el-form-item>
            <el-form-item label="运营商姓名：">
                <el-input size="small" v-model="searchForm.faname" placeholder="请输入运营商姓名"></el-input>
            </el-form-item>
            <el-form-item label="发展商姓名：">
                <el-input size="small" v-model="searchForm.regname" placeholder="请输入发展商姓名"></el-input>
            </el-form-item>
            <el-form-item label=" " label-width="25px">
                <el-button type="primary" size="small" @click="search">搜索</el-button>
                <el-button type="text" size="small" @click="clearSearch">清空搜索条件</el-button>
            </el-form-item>
        </el-form>
        <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
            <template slot="empty">
                <No />
            </template>
            <el-table-column prop="sn" label="二维码" width="200" align="center"></el-table-column>
            <el-table-column prop="merchant_name" label="店铺名称" width="200" align="center"></el-table-column>
            <el-table-column prop="contact_name" label="商家姓名" align="center"></el-table-column>
            <el-table-column prop="store_phone" label="商家电话" align="center" width="150"></el-table-column>
            <el-table-column prop="area" label="所属区域" width="200" align="center">
                <template v-slot="{ row }">
                    {{ row.area || '--' }}
                </template>
            </el-table-column>
            <el-table-column prop="name" label="服务商姓名" width="200" align="center">
                <template v-slot="{ row }">
                    {{ row.name || '--' }}
                </template>
            </el-table-column>
            <!--<el-table-column prop="grade" label="授权级别" width="100" align="center">
                <template v-slot="{ row }">
                    {{ row.grade == 1 ? '市级' : '区级' }}
                </template>
            </el-table-column>-->
            <!--<el-table-column prop="facilitator_area" label="授权区域" width="200" align="center"></el-table-column>-->
            <el-table-column prop="mobile" label="服务商电话" width="150" align="center">
                <template v-slot="{ row }">
                    {{ row.mobile || '--' }}
                </template>
            </el-table-column>
            <el-table-column prop="regimental_name" label="团长姓名" width="200" align="center">
                <template v-slot="{ row }">
                    {{ row.regimental_name || '--' }}
                </template>
            </el-table-column>
            <el-table-column prop="regimental_mobile" label="团长电话" width="150" align="center">
                <template v-slot="{ row }">
                    {{ row.regimental_mobile || '--' }}
                </template>
            </el-table-column>
            <el-table-column prop="store_site" label="店铺地址" align="center" width="200"></el-table-column>
            <el-table-column prop="store_logo" label="店铺logo" width="100" align="center">
                <template v-slot="{ row }">
                    <el-image style="width: 50px; height: 50px; border-radius: 50%" :src="row.store_logo"></el-image>
                </template>
            </el-table-column>
            <el-table-column prop="business_license" label="经营资质" width="150" align="center">
                <template v-slot="{ row }">
                    <el-image style="width: 100px; height: 50px" fit="cover" :src="row.business_license"
                        :preview-src-list="[row.business_license]"></el-image>
                </template>
            </el-table-column>
            <el-table-column prop="status" label="状态" align="center">
                <template v-slot="{ row }">
                    <span>
                        {{ row.video_status == 0 ? '待审核' : row.video_status == 1 ? '已通过' : row.video_status ==
                            -1 ? '未通过' : '未上传' }}
                    </span>
                </template>
            </el-table-column>
            <el-table-column prop="create_time" label="上传视频起止时间" width="300" align="center">
                <template v-slot="{ row }">{{ getDateformat(row.create_time) }}~{{ getDateformat(row.end_time_to_upload)
                }}</template>
            </el-table-column>
            <el-table-column prop="status" label="店铺状态" align="center">
                <template v-slot="{ row }">
                    <span>
                        {{ row.is_black == 0 ? '正常' : '已拉黑' }}
                    </span>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center" fixed="right" width="200">
                <template v-slot="{ row }">
                    <el-button v-if="row.labeling_video" @click="(video = row.labeling_video), (showVideo = true)"
                        type="text" size="small">查看视频</el-button>
                    <el-button v-if="row.video_status == 0" @click="review(row, 1)" type="text" size="small">通过</el-button>
                    <el-button v-if="row.video_status == 0" @click="review(row, -1)" type="text" size="small">驳回</el-button>
                    <el-button v-if="row.video_status == -1" @click="(remark = row.video_refuse_reason), (showRemark = !0)"
                        type="text" size="small">驳回原因</el-button>
                </template>
            </el-table-column>
        </el-table>
        <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData">
        </Paging>
        <el-dialog title="审核" :visible.sync="showReview" width="600px">
            <el-form label-width="140px">
                <el-form-item label="请选择审核结果：">
                    <el-radio-group v-model="status">
                        <el-radio :label="1">通过</el-radio>
                        <el-radio :label="-1">驳回</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="请添加驳回原因：" v-if="status == -1">
                    <el-input type="textarea" :rows="4" v-model="remark"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showReview = !1">取 消</el-button>
                <el-button type="primary" @click="comfirmReview">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog title="驳回原因" :visible.sync="showRemark" width="600px">
            {{ remark }}
        </el-dialog>
        <el-dialog title="查看视频" :visible.sync="showVideo" width="800px">
            <video :src="video" style="width:686px;height:400px;" controls>
                您的浏览器不支持 video 标签。
            </video>
        </el-dialog>
    </el-main>
</template>
  
<script>
import _ from 'lodash';
import { getDateformat } from '@/util/getDate';
import Paging from '@/components/paging';
export default {
    components: {
        Paging,
    },
    data () {
        return {
            list: [],
            total_number: 0,
            searchForm: {
                page: 1,
                rows: 10,
                video_status: 999,
                merchant_name: '',
                contact_name: '',
                store_phone: '',
                regname: '',
                faname: '',
                start_time: '',
                end_time: ''
            },
            time: '',

            showReview: !1,
            showRemark: !1,
            id: '',
            status: 2,
            remark: '',
            showVideo: false,
            video: ''
        };
    },
    created () {
        this.getList();
    },
    methods: {
        search () {
            this.page = 1;
            this.getList();
        },
        clearSearch () {
            this.searchForm = {
                page: 1,
                rows: 10,
                video_status: 999,
                merchant_name: '',
                contact_name: '',
                store_phone: '',
                regname: '',
                faname: '',
                start_time: '',
                end_time: ''
            };
            this.time = ''
            this.getList();
        },
        getDateformat (val) {
            return getDateformat(val);
        },
        updateData (val, status) {
            if (status == 0) {
                this.searchForm.rows = val;
                this.getList();
            } else {
                this.searchForm.page = val;
                this.getList();
            }
        },
        getList () {
            let searchForm = _.cloneDeep(this.searchForm);
            if (searchForm.video_status == 999) {
                delete searchForm.video_status
            }
            if (this.time) {
                searchForm.start_time = this.time[0] / 1000;
                searchForm.end_time = this.time[1] / 1000;
            } else {
                searchForm.start_time = '';
                searchForm.end_time = ''
            }
            this.$axios.post(this.$api.serviceProvider.videoList, searchForm).then(res => {
                if (res.code == 0) {
                    this.list = res.result.list;
                    this.total_number = res.result.total_number;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        review (row, status) {
            this.status = status;
            this.id = row.id;
            this.remark = '';
            this.showReview = !0;
        },
        comfirmReview () {
            if (!this.remark && this.status == -1) return this.$message.warning('请添加驳回原因');
            this.$axios
                .post(this.$api.serviceProvider.upVideoStatus, {
                    id: this.id,
                    video_status: this.status,
                    video_refuse_reason: this.remark,
                })
                .then(res => {
                    if (res.code == 0) {
                        this.showReview = !1;
                        this.$message.success('处理成功');
                        this.getList();
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
    },
};
</script>
  
<style lang="less" scoped>
.el-main {
    background: #fff;
}
</style>